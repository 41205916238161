
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import { ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';

@Component({
  components: {
    GAnalytics,
  },
})
export default class GAssessmentBanner extends Vue {
  get beginAssessment(): RawLocation {
    return {
      name: RouteNames.LandingPage,
    };
  }

  get learnMoreAnalytics(): ClickData {
    return {
      clickTarget: 'RPA Bots',
    };
  }
}
