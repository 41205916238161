
import { Component, Vue } from 'vue-property-decorator';
import sumBy from 'lodash/sumBy';
import { Location } from 'vue-router';
import FilterSelector from '@/components/FilterSelector.vue';
import { SelectOption } from '@/components/form/form.types';
import { getQuery, slugify, updateQuery } from '@/utils/routing';
import { ListingsModule } from '@/store/modules/listings.module';
import NavigationList from '@/components/NavigationList.vue';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    FilterSelector,
    NavigationList,
    GButton,
  },
})
export default class CatalogSearchBreadcrumb extends Vue {
  get listingType() {
    const { type } = this.$route.params;
    return ListingsModule.listingTypes.find(t => slugify(t.listingTypeName) === type);
  }

  get anyResults() {
    return this.n > -1;
  }

  get anyFilters() {
    return Boolean(this.businessUnits.length || this.keywords.length || this.categories.length);
  }

  get anyFiltersOrSearch() {
    return this.anyFilters || !!this.searchQuery;
  }

  get filterCount() {
    return (
      this.businessUnits.length +
      this.keywords.length +
      this.categories.length +
      Number(!!this.searchQuery)
    );
  }

  get n() {
    return sumBy(ListingsModule.searchResults, sr => sr.count);
  }

  get searchQuery() {
    return getQuery(this, 'q', { toArray: false, toNumber: false });
  }

  get businessUnits() {
    return this.buOptions.map(opt => opt.value);
  }
  set businessUnits(v) {
    updateQuery(this, 'businessUnitIds', v).catch(() => {});
  }
  get buOptions(): SelectOption[] {
    const ids = getQuery(this, 'businessUnitIds', { toArray: true, toNumber: false });
    return ListingsModule.businessUnits
      .map(bu => ({
        value: bu.businessUnitId.toString(),
        label: bu.businessUnitName,
      }))
      .filter(opt => ids.includes(opt.value));
  }

  get keywords() {
    return this.keywordOptions.map(opt => opt.value);
  }
  set keywords(v) {
    updateQuery(this, 'keyword', v).catch(() => {});
  }
  get keywordOptions(): SelectOption[] {
    const ids = getQuery(this, 'keyword', { toArray: true, toNumber: false });
    return ListingsModule.keywords
      .map(k => ({
        value: k.keywordId.toString(),
        label: k.keywordValue,
      }))
      .filter(opt => ids.includes(opt.value));
  }

  get categories() {
    return this.categoryOptions.map(opt => opt.value);
  }
  set categories(v) {
    updateQuery(this, 'categoryId', v).catch(() => {});
  }
  get categoryOptions(): SelectOption[] {
    const ids = getQuery(this, 'categoryId', { toArray: true, toNumber: false });
    return (
      this.listingType?.category
        .map(cat => ({
          value: cat.categoryId.toString(),
          label: cat.categoryName,
        }))
        .filter(opt => ids.includes(opt.value)) ?? []
    );
  }

  clear() {
    const r = { ...this.$route };
    r.query = {};
    this.$router.push(r as Location);
  }
}
