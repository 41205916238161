
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import CardListWithHeader from '@/components/catalog/CardListWithHeader/CardListWithHeader.vue';
import SearchBanner from '@/components/catalog/SearchBanner/SearchBanner.vue';
import {
  ListingsModule,
  ListingsModule as ListingCatalogModule,
  ListingsPaginated,
} from '@/store/modules/listings.module';
import GButton from '@/components/gsk-components/GskButton.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';
import { RouteNames } from '@/constants';
import { getQuery, slugify } from '@/utils/routing';
import MaxWidth from '@/components/MaxWidth.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import CatalogFilters from '@/components/catalog/CatalogFilters.vue';
import { SearchFilter } from '@/api/listings.api';
import CatalogSearchBreadcrumb from '@/components/catalog/CatalogSearchBreadcrumb.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import { UINavigationItem } from '@/types';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';

@Component({
  components: {
    CardListWithHeader,
    SearchBanner,
    GButton,
    NavigationTabs,
    MaxWidth,
    CatalogFilters,
    CatalogSearchBreadcrumb,
    BreadCrumbs,
    Grid,
    GridCell,
  },
})
export default class ListingsCatalogView extends Vue {
  protected filters: { search: string } = {
    search: '',
  };

  get listingType() {
    const { type } = this.$route.params;
    return ListingsModule.listingTypes.find(t => slugify(t.listingTypeName) === type);
  }

  get typeHeading() {
    return this.listingType?.listingTypeName ?? '';
  }

  async created() {
    const { getAllEnumTypes, hasEnums } = EnumsModule;
    if (hasEnums) {
      this.callNoAwait(getAllEnumTypes);
    } else {
      await getAllEnumTypes();
    }
  }

  get breadCrumb(): UINavigationItem[] {
    return [
      {
        text: 'Product Catalog',
        key: 'pc',
        route: this.catalogLink,
      },
      {
        text: this.typeHeading,
        key: this.typeHeading,
        route: this.catalogLink,
      },
    ];
  }

  get filterOptions() {
    const bus = getQuery(this, 'businessUnitIds', { toArray: true, toNumber: false });
    const cat = getQuery(this, 'categoryId', { toArray: true, toNumber: false });

    return [...cat, ...bus];
  }

  callNoAwait(fn: () => void): void {
    fn();
  }

  protected searchListing(value: string) {
    let query;
    let loc: RawLocation;
    if (this.$route.params.type) {
      query = { ...this.$route.query, q: value };
      loc = {
        name: RouteNames.ListingType,
        params: this.$route.params,
        query,
      };
    } else {
      query = { q: value };
      loc = {
        name: RouteNames.Listings,
        query,
      };
    }
    if (this.$route.query?.q === value || (!this.$route.query?.q && !value)) {
      return;
    }
    if (!value) {
      delete loc.query?.q;
    }
    this.$router.push(loc);
  }

  public loading = true;
  public initialLoading = true;

  get hasSearchQuery(): boolean {
    return !!this.$route.query.q;
  }

  get hasTypes(): boolean {
    return ListingCatalogModule.listingTypes.length > 0;
  }

  get searchQuery(): string {
    if (this.hasSearchQuery) {
      const { q } = this.$route.query;
      return this.routerQueryString(q);
    }
    return '';
  }

  get numSearchResults(): number {
    return this.catalogs.reduce((count, listing) => {
      return count + listing.count;
    }, 0);
  }

  get numResultsText(): string {
    const count = this.numSearchResults;
    if (count === 1) {
      return count + ' result';
    } else {
      return count + ' results';
    }
  }

  protected routerQueryString(q: string | (string | null)[]): string {
    return Array.isArray(q) ? q.join(',') : q;
  }

  @Watch('$route', { deep: true, immediate: true })
  async getListingTypes() {
    let { listingTypes } = ListingCatalogModule;

    if (listingTypes.length < 1) {
      // Listing Types doesn't exist in local storage
      this.loading = true;
      await ListingCatalogModule.getCatalogListingTypes();
      listingTypes = ListingCatalogModule.listingTypes;
    } else {
      ListingCatalogModule.getCatalogListingTypes();
    }

    const { type } = this.$route.params;
    const listingType = listingTypes.find(t => slugify(t.listingTypeName) === type);
    const query = getQuery(this, 'q', { toArray: false, toNumber: false });

    const filter: SearchFilter = {
      isFeatured: !type && !query,
      typeId: listingType?.listingTypeId ?? 0,
      query,
      businessUnitId: getQuery(this, 'businessUnitIds', {
        toArray: true,
        toNumber: true,
      }),
      keyword: getQuery(this, 'keyword', {
        toArray: true,
        toNumber: true,
      }),
      categoryId: getQuery(this, 'categoryId', {
        toNumber: true,
        toArray: false,
      }),
    };
    if (filter.isFeatured) {
      delete filter.typeId;
    } else {
      delete filter.isFeatured;
    }
    if (filter.typeId === 0) {
      delete filter.typeId;
    }
    if (!filter.categoryId) {
      delete filter.categoryId;
    }
    if (!filter.keyword?.length) {
      delete filter.keyword;
    }
    if (!filter.businessUnitId?.length) {
      delete filter.businessUnitId;
    }
    if (!filter.query) {
      delete filter.query;
    }
    this.loading = true;

    await ListingCatalogModule.search(filter);

    this.loading = false;
    this.initialLoading = false;
  }

  get empty(): boolean {
    return this.catalogs.length === 0;
  }

  get catalogLink(): import('vue-router').Location {
    return { name: RouteNames.Listings };
  }

  get catalogs(): ListingsPaginated[] {
    return ListingCatalogModule.searchResults;
  }

  get bannerData() {
    return {
      bannerText: 'Explore, launch and manage solutions',
      searchInfo: '???',
    };
  }
}
