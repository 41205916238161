
import { Component, Prop, Vue } from 'vue-property-decorator';
import ListingCard from './ListingCard.vue';
import TableList from '@/components/catalog/TableList.vue';
import { ListingsPaginated } from '@/store/modules/listings.module';
import MaxWidth from '@/components/MaxWidth.vue';
import { RouteNames } from '@/constants';
import { slugify } from '@/utils/routing';
import GAssessmentBanner from '@/Rpa/components/GskAssessmentBanner.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FeatureFlag from '@/components/FeatureFlag';

@Component({
  components: {
    ListingCard,
    MaxWidth,
    TableList,
    GAssessmentBanner,
    Grid,
    GridCell,
    FeatureFlag,
  },
})
export default class CardListWithHeader extends Vue {
  showAll = false;
  showTable = false;

  //props
  @Prop(Object) catalog!: ListingsPaginated;
  @Prop(String) header!: string;
  @Prop(String) description!: string;

  @Prop()
  protected filters!: { search: string };

  get headerText(): string {
    if (this.$route.name === RouteNames.ListingType) {
      return '';
    } else {
      return this.header;
    }
  }

  get typeLink() {
    return {
      name: 'catalog-type',
      params: {
        type: slugify(this.header),
      },
    };
  }

  get isOnTab(): boolean {
    return !!this.$route.params.type || !!this.$route.query.q;
  }

  get isRPA(): boolean {
    return this.header === 'RPA';
  }

  updateShowTable(bool: boolean): void {
    this.showTable = bool;
  }

  get listings() {
    return this.catalog.listings;
  }
}
