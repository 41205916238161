
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RpaListing } from '@/types/listings.types';
import { RouteNames, listingTypesById } from '@/constants';
import { addAnalyticsRouteParams } from '@/analytics';

@Component
export default class TableList extends Vue {
  @Prop(Array) readonly listings!: RpaListing[];

  public listingLink(listing: RpaListing): any {
    const listingType = listingTypesById[listing.listingTypeId];
    return {
      name: RouteNames.ListingDetails,
      params: addAnalyticsRouteParams(
        { listingId: '' + listing.listingId },
        {
          listingName: listing.listingName,
          listingType,
        },
      ),
    };
  }

  public deliveredBy(listing: RpaListing): string {
    let res = '';
    listing.details.forEach(detail => {
      if (detail.key === 'delivered-by') {
        res = detail.value;
      }
    });
    return res;
  }

  public deliveredFor(listing: RpaListing): string {
    let res = '';
    listing.details.forEach(detail => {
      if (detail.key === 'delivered-for') {
        res = detail.value;
      }
    });
    return res;
  }

  public systems(listing: RpaListing): string {
    let res = '';
    listing.details.forEach(detail => {
      if (detail.key === 'systems-integrations') {
        if (Array.isArray(detail.value)) {
          res = detail.value.join(', ');
        } else {
          res = detail.value;
        }
      }
    });
    return res;
  }
}
