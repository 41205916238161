
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { TextfieldInfo } from '@/constants';
import GAnalytics from '@/components/GAnalytics';
import { ClickData } from '@/analytics';
import { getQuery } from '@/utils/routing';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';

@Component({
  components: {
    GTextfield,
    GAnalytics,
    Grid,
    GridCell,
  },
})
export default class SearchBanner extends Vue {
  @Prop() bannerInfo!: {
    bannerText: string;
    searchInfo: string;
  };
  @Prop(Boolean) hideHeader!: boolean;

  public searchPlaceholder: string = TextfieldInfo.searchPlaceholder;
  public value = '';

  get searchAnalytics(): ClickData {
    return {
      clickTarget: 'product-catalog-search',
      searchQuery: this.value,
    };
  }

  @Watch('$route.query', { deep: true, immediate: true })
  getValue() {
    this.value =
      getQuery(this, 'q', {
        toArray: false,
        toNumber: false,
      }) || '';
  }

  get header(): string {
    return this.bannerInfo.bannerText;
  }

  @Emit('search')
  protected onSearch(value: string) {
    return value.trim();
  }
}
